import classes from './About.component.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import { useTranslation } from '@lobox/utils';
import InfoCard from '@shared/components/Organism/Objects/Common/InfoCard';
import SectionLayout from '@shared/components/Organism/Objects/Common/Section.layout';
import useProfilePage from '@shared/hooks/useProfilePage';
import {
  useProfileModals,
  useVisibleProfileSectionActionButton,
} from '@shared/hooks';
import { EDIT_PROFILE } from '@shared/constants/profileModalsKeys';
import useAboutSectionData from './about.useData';
import AboutSectionSkeleton from '../../components/AboutSectionSkeleton.component';

const AboutSection: React.FC = () => {
  const { t } = useTranslation();
  const { open } = useProfileModals();
  const data = useAboutSectionData();
  const { isAuthUser, isLoading } = useProfilePage();
  const visibleActionButton = useVisibleProfileSectionActionButton();

  const onEditHandler = () => {
    open(EDIT_PROFILE);
  };

  if (isLoading) {
    return <AboutSectionSkeleton />;
  }
  if (data?.length === 0) {
    return null;
  }

  return (
    <SectionLayout
      onEditHandler={onEditHandler}
      title={t('about')}
      classNames={{ childrenWrap: classes.childrenWrap }}
      visibleActionButton={visibleActionButton}
    >
      {data.map(({ id, title, subTitle, value, icon, onClick }) => (
        <BaseButton disabled={!isAuthUser} onClick={onClick} key={id}>
          <InfoCard
            {...{
              disabledHover: !isAuthUser,
              title,
              subTitle,
              value,
              icon,
            }}
          />
        </BaseButton>
      ))}
    </SectionLayout>
  );
};

export default AboutSection;
