'use client';

import classes from './page.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import { RESUME_LINK_VALUES, PAGE_ROLES, useAuthState } from '@lobox/utils';
import { useProfileModals, useProfilePage } from '@shared/hooks';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import useGetRole from '@shared/hooks/useGetRole';
import dynamic from 'next/dynamic';
import ContactInfoSection from '@shared/components/Organism/Objects/Page/ContactInfo/ContactInfo.component';
import OverviewSection from '@shared/components/Organism/Objects/Page/Overview/Overview.component';
import {
  ASK_RECOMMENDATION,
  BIO,
  COURSE,
  HONOR,
  LICENCE,
  PATENT,
  PRO_EXPERIENCE,
  PUBLICATION,
  SCHOOL,
  VOL_EXPERIENCE,
} from '@shared/constants/profileModalsKeys';
import AboutSection from './partials/sections/About/About.component';
import AddSectionButton from './partials/components/AddSectionButton';
import type * as MODAL_KEYS from '@shared/constants/profileModalsKeys';
import BioSection from './partials/sections/Bio/Bio.component';
import useGetAboutSectionsData from '@shared/hooks/api-hook/useGetAboutSectionsData';
import LoadingAbout from './loading';

const LocationSection = dynamic(
  () =>
    import(
      '@shared/components/Organism/Objects/Page/Location/Location.component'
    ),
  { ssr: false }
);

const PrivateAccount = dynamic(
  () => import('./partials/components/PrivateAccount'),
  { ssr: false }
);

const ProfessionalExperience = dynamic(
  () =>
    import('./partials/sections/Experience/ProfessionalExperience.component'),
  { ssr: false }
);
const VolunteerExperiences = dynamic(
  () => import('./partials/sections/Experience/VolunteerExperiences.component'),
  { ssr: false }
);

const ResumeSection = dynamic(
  () => import('./partials/sections/Resume/Resume.component'),
  { ssr: false }
);
const SkillSection = dynamic(
  () => import('./partials/sections/Skill/Skill.component'),
  { ssr: false }
);
const LanguageSection = dynamic(
  () => import('./partials/sections/Language/Language.component'),
  { ssr: false }
);
const EducationSection = dynamic(
  () => import('./partials/sections/Education/Education.component'),
  { ssr: false }
);

const CompleteSections = dynamic(
  () => import('./partials/sections/CompleteSection/CompleteSection.component'),
  { ssr: false }
);
const Recommendation = dynamic(
  () => import('./partials/sections/Recommendation/Recommendation.component'),
  { ssr: false }
);
const CourseSection = dynamic(
  () => import('./partials/sections/Course/Course.component'),
  { ssr: false }
);
const LicenceSection = dynamic(
  () => import('./partials/sections/Licence/Licence.component'),
  { ssr: false }
);
const Publication = dynamic(
  () => import('./partials/sections/UserAccomplishment/Publication.component'),
  { ssr: false }
);

const HonorAwards = dynamic(
  () => import('./partials/sections/UserAccomplishment/HonorAwards.component'),
  { ssr: false }
);
const Patent = dynamic(
  () => import('./partials/sections/UserAccomplishment/Patent.component'),
  { ssr: false }
);

const LoginSection = dynamic(
  () => import('./partials/sections/Login/Login.component'),
  { ssr: false }
);

const About: React.FC = () => {
  const { modals } = useProfileModals();
  const isEditMode = !!modals.selectedForEdit;
  const { data, isLoading } = useGetAboutSectionsData();
  const isLoggedIn = useAuthState('isLoggedIn');

  const {
    isAuthUser,
    network,
    isPage,
    checkSectionVisibility,
    isPrivateAccount,
    isLoading: isProfilePageLoading,
  } = useProfilePage();
  const { roles } = useGetRole();
  const isFiled = [
    data?.experiences,
    data?.certifications,
    data?.courses,
    data?.experiences,
    data?.publications,
    data?.patents,
    data?.educations,
    data?.awards,
    data?.receivedRecommendations,
    data?.givenRecommendations,
  ].some((item) => (item?.length || 0) > 0);

  const checkIsSelectedForEdit = (modalKey: keyof typeof MODAL_KEYS) => {
    if (!modals?.selectedForEdit) return true;
    return modals.selectedForEdit === modalKey;
  };
  const visibleBio =
    isLoading ||
    isProfilePageLoading ||
    ((data?.bio || !isFiled) &&
      !isPage &&
      checkSectionVisibility('visibleBio') &&
      checkIsSelectedForEdit(BIO));
  const visibleEducation =
    checkSectionVisibility('visibleEducation') &&
    !isPrivateAccount &&
    checkIsSelectedForEdit(SCHOOL);
  const visibleProExperience = checkIsSelectedForEdit(PRO_EXPERIENCE);
  const visibleVolExperience = checkIsSelectedForEdit(VOL_EXPERIENCE);
  const visibleCourses =
    checkSectionVisibility('visibleCourses') &&
    !isPrivateAccount &&
    checkIsSelectedForEdit(COURSE);
  const visibleRecommendations =
    checkSectionVisibility('visibleRecommendations') &&
    !isPrivateAccount &&
    checkIsSelectedForEdit(ASK_RECOMMENDATION);
  const visibleSkills =
    checkSectionVisibility('visibleSkills') && !isPrivateAccount;
  const visibleLanguages =
    checkSectionVisibility('visibleLanguages') && !isPrivateAccount;
  const visibleResume =
    checkSectionVisibility('visibleResume') && !isPrivateAccount;
  const visiblePublications =
    checkSectionVisibility('visiblePublications') &&
    !isPrivateAccount &&
    checkIsSelectedForEdit(PUBLICATION);
  const visibleHonorAwards =
    checkSectionVisibility('visibleHonorAwards') &&
    !isPrivateAccount &&
    checkIsSelectedForEdit(HONOR);
  const visiblePatent =
    checkSectionVisibility('visiblePatent') &&
    !isPrivateAccount &&
    checkIsSelectedForEdit(PATENT);

  const visibleCurrentExperience =
    checkSectionVisibility('visibleCurrentExperience') && !isPrivateAccount;
  const visiblePastExperience =
    checkSectionVisibility('visiblePastExperience') && !isPrivateAccount;
  const visibleLicence = !isPrivateAccount && checkIsSelectedForEdit(LICENCE);

  const checkIsResumeVisible = () => {
    if (data?.resumeLinkPermit === RESUME_LINK_VALUES.RECRUITERS.value) {
      return isBusinessApp && roles.includes(PAGE_ROLES.RECRUITER.value);
    }
    if (data?.resumeLinkPermit === RESUME_LINK_VALUES.FOLLOWERS.value) {
      return network?.back;
    }
    if (data?.resumeLinkPermit === RESUME_LINK_VALUES.ONLY_SHARE.value) {
      return isAuthUser;
    }
    return true;
  };

  return isLoading ? (
    <LoadingAbout />
  ) : (
    <Flex className={classes.aboutWrap}>
      {isAuthUser && !isBusinessApp && !isEditMode && <AddSectionButton />}
      <Flex className={classes.desktopRow}>
        <Flex className={classes.left}>
          {isPage && <OverviewSection />}
          {isPage && <LocationSection />}
          {visibleBio && <BioSection />}
          {isPrivateAccount && !isLoading && (
            <PrivateAccount largeMarginTop={!visibleBio} />
          )}
          {!isPage && isFiled && (
            <>
              {(visibleCurrentExperience || visiblePastExperience) && (
                <>
                  {visibleProExperience && <ProfessionalExperience />}
                  {visibleVolExperience && <VolunteerExperiences />}
                </>
              )}
              {visibleEducation && <EducationSection />}
              {visibleCourses && <CourseSection />}
              {visibleLicence && <LicenceSection />}
              {visiblePublications && <Publication />}
              {visibleHonorAwards && <HonorAwards />}
              {visiblePatent && <Patent />}
              {visibleRecommendations && <Recommendation />}
            </>
          )}
          {isAuthUser && !isFiled && <CompleteSections />}
          {!!data?.givenRecommendations?.length && !isFiled && (
            <Recommendation />
          )}
        </Flex>
        <Flex className={classes.right}>
          {!isLoggedIn ? <LoginSection /> : null}
          {isPage ? (
            <ContactInfoSection />
          ) : (
            <>
              <Flex className={classes.rightOrder}>
                <Flex>
                  <AboutSection />
                </Flex>
                <Flex>
                  {visibleSkills && <SkillSection />}
                  {visibleLanguages && <LanguageSection />}
                </Flex>
              </Flex>

              {((checkIsResumeVisible() && visibleResume) || isAuthUser) && (
                <ResumeSection />
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default About;
