import classes from './AboutSectionSkeleton.component.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Paper from '@lobox/uikit/Paper';
import Skeleton from '@lobox/uikit/Skeleton';

const AboutSectionSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton className={classes.bioSectionSkeletonTitle} />
      <Paper noHover contentClassName={classes.bioSectionSkeleton}>
        {[1, 2, 3].map((i) => (
          <Flex key={i} className={classes.mediaSkeletonWrap}>
            <Skeleton className={classes.circle} />
            <Skeleton className={classes.txt} />
          </Flex>
        ))}
      </Paper>
    </>
  );
};

export default AboutSectionSkeleton;
