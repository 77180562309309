import classes from './about.useData.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import DisplayPhoneNumber from '@lobox/uikit/PhoneInput/DisplayPhoneNumber';
import OverflowTip from '@lobox/uikit/Typography/OverflowTip';
import {
  checkLinkPrefix,
  useTranslation,
  formatDate,
  mailTo,
  callTo,
  routeNames,
  preventClickHandler,
  useAuthState,
} from '@lobox/utils';
import { useGetAboutSectionsData } from '@shared/hooks/api-hook';
import { useHistory } from '@lobox/utils';
import {
  DATE_OF_BIRTH,
  LOCATION,
  PHONE_NUMBER,
  WEB_SITE,
} from '@shared/constants/profileModalsKeys';
import { useProfileModals } from '@shared/hooks';
import useProfilePage from '@shared/hooks/useProfilePage';
import isEmpty from 'lodash/isEmpty';

type AboutSectionType = {
  id: string;
  title: string;
  subTitle?: string;
  value: any;
  icon: string;
  to?: string;
  onClick?: () => void;
};

const useAboutSectionData = (): Array<AboutSectionType> => {
  const { t } = useTranslation();
  const { open } = useProfileModals();
  const history = useHistory();
  const isLoggedIn = useAuthState('isLoggedIn');
  const {
    isAuthUser,
    checkPrivacy,
    objectDetail: user,
    checkSectionVisibility,
    isPrivateAccount,
  } = useProfilePage();
  const { data: userProfile } = useGetAboutSectionsData();
  const email = user?.email;
  const phone = user?.phone;
  const birthDate = user?.birthDate;
  const location = user?.location;
  const linkHasValue = userProfile?.links?.filter((i) => i !== '')?.length;

  const visibleLinks =
    isAuthUser ||
    (linkHasValue && !isPrivateAccount && isLoggedIn) ||
    (!isLoggedIn &&
      !isPrivateAccount &&
      linkHasValue &&
      checkSectionVisibility('visibleLink'));
  const visibleLocation =
    checkSectionVisibility('visibleLocation') && !!location;
  const visibleEmail =
    isAuthUser ||
    (isLoggedIn && checkPrivacy(email)) ||
    (!isLoggedIn &&
      !isPrivateAccount &&
      email?.value &&
      checkSectionVisibility('visibleEmail'));

  const visiblePhone =
    isAuthUser ||
    (isLoggedIn && checkPrivacy(phone)) ||
    (!isLoggedIn &&
      !isPrivateAccount &&
      phone?.value &&
      checkSectionVisibility('visiblePhone'));

  const visibleDateOfBirth =
    isAuthUser || (isLoggedIn && checkPrivacy(birthDate));

  const links = userProfile?.links?.filter((i) => !isEmpty(i));

  return [
    visibleDateOfBirth && {
      id: DATE_OF_BIRTH,
      onClick: () => open(DATE_OF_BIRTH),
      title: t('date_of_birth'),
      subTitle: t('click_to_add_birthday'),
      value: !birthDate?.value ? undefined : formatDate(birthDate?.value),
      icon: 'calendar-alt',
    },
    visibleEmail && {
      id: 'emil',
      title: t('email_address'),
      subTitle: t('click_to_add_email'),
      onClick: () => history.push(routeNames.settingsContactInfo),
      value: email?.value ? (
        <BaseButton {...(isAuthUser ? {} : { href: mailTo(email?.value) })}>
          <OverflowTip
            size={15}
            color="thirdText"
            mt={5}
            className={classes.subtitle}
            height={21}
          >
            {email.value}
          </OverflowTip>
        </BaseButton>
      ) : undefined,
      icon: 'envelope',
    },
    visiblePhone && {
      id: 'phone',
      title: t('phone'),
      subTitle: t('click_to_add_phone'),
      onClick: () => open(PHONE_NUMBER),
      value: phone?.value ? (
        <BaseButton {...(isAuthUser ? {} : { href: callTo(phone?.value) })}>
          <DisplayPhoneNumber
            className={classes.phoneStyle}
            value={phone?.value}
          />
        </BaseButton>
      ) : undefined,
      icon: 'phone',
    },
    visibleLinks && {
      id: WEB_SITE,
      onClick: () => open(WEB_SITE),
      title: t('links'),
      subTitle: t('click_to_add_links'),
      value:
        links?.length > 0
          ? links?.map((link: any) => (
              <BaseButton
                onClick={(event) => {
                  // @ts-ignore
                  window.open(checkLinkPrefix(link), '_blank').focus();
                  preventClickHandler(event);
                }}
                key={link}
                target="_blank"
              >
                <OverflowTip
                  size={15}
                  color="thirdText"
                  mt={5}
                  className={classes.subtitle}
                  height={21}
                >
                  {link}
                </OverflowTip>
              </BaseButton>
            ))
          : undefined,
      icon: 'link',
    },
    visibleLocation && {
      id: LOCATION,
      onClick: () => open(LOCATION),
      title: t('location'),
      subTitle: t('click_to_add_location'),
      value: location?.title || '',
      icon: 'map-marker-alt',
    },
  ].filter(Boolean) as [];
};

export default useAboutSectionData;
