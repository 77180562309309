import classes from './Bio.component.module.scss';

import React from 'react';
import { useHistory } from '@lobox/utils';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Card from '@lobox/uikit/Card';
import Flex from '@lobox/uikit/Flex';
import Paper from '@lobox/uikit/Paper';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import { routeNames, useTranslation } from '@lobox/utils';
import { useGetAboutSectionsData } from '@shared/hooks/api-hook';
import { BIO } from '@shared/constants/profileModalsKeys';
import SectionLayout from '@shared/components/Organism/Objects/Common/Section.layout';
import {
  useProfileModals,
  useProfilePage,
  useVisibleProfileSectionActionButton,
} from '@shared/hooks';
import useHasPermission from '@shared/hooks/useHasPermission';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import BiographyPlus from '@shared/svg/BiographyPlusIcon';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';
import { useObjectClicks } from '@shared/hooks/useObjectClicks';

const BioSection = (): JSX.Element => {
  const { open } = useProfileModals();
  const { t } = useTranslation();
  const { data } = useGetAboutSectionsData();
  const isFiled = data?.bio?.length > 0;
  const { isAuthUser, objectDetail: user } = useProfilePage();
  const canSeeHomeScreen = useHasPermission([SCOPES.canSeeHomeScreen]);
  const visibleActionButton = useVisibleProfileSectionActionButton();

  const onEditHandler = isAuthUser ? () => open(BIO) : undefined;

  const { handleTagClick, handleHashtagClick } = useObjectClicks();

  return (
    <>
      <SectionLayout
        classNames={{
          title: classes.title,
          childrenWrap: classes.childrenWrap,
        }}
        title={t('bio')}
        onEditHandler={onEditHandler}
        visibleActionButton={visibleActionButton}
      >
        <Flex>
          {!isFiled ? (
            <BaseButton disabled={!isAuthUser} onClick={onEditHandler}>
              <Card
                title={isAuthUser ? t('write_your_bio') : undefined}
                subTitle={
                  isAuthUser
                    ? t('write_your_bio_helper')
                    : `${user?.name} ${t('empty_bio')}`
                }
                image={<BiographyPlus className={classes.cardImage} />}
                direction="row-reverse"
                className={classes.events}
                noHover={!isAuthUser}
                subTitleClassName={classes.subTitleWrap}
              />
            </BaseButton>
          ) : (
            <Paper
              noHover
              className={classes.paperRoot}
              contentClassName={classes.contentClassName}
            >
              <RichTextView
                html={data?.bio as string}
                typographyProps={{
                  color: 'thirdText',
                }}
                row={4}
                showMore
                onMentionClick={handleTagClick}
                onHashtagClick={handleHashtagClick}
              />
            </Paper>
          )}
        </Flex>
      </SectionLayout>
    </>
  );
};

export default BioSection;
