import classes from './AddSectionButton.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import useTheme from '@lobox/uikit/utils/useTheme';
import { ADD_SECTION } from '@shared/constants/profileModalsKeys';
import { useProfileModals } from '@shared/hooks';
import { useTranslation } from '@lobox/utils';

const AddSectionButton: React.FC = () => {
  const { open } = useProfileModals();
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const openAddSection = () => open(ADD_SECTION);

  return (
    <Flex className={classes.addSectionRoot}>
      <Button
        schema={isDark ? 'ghost-black' : 'ghost'}
        label={t('add_section')}
        leftIcon="plus"
        onClick={openAddSection}
      />
    </Flex>
  );
};

export default AddSectionButton;
