import * as React from 'react';

function BiographyPlusIcon(props: any) {
  return (
    <svg
      width={55}
      height={51}
      viewBox="0 0 55 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#BiographyPlusIcon0)">
        <path
          d="M47.156 31.969H20.25a3.282 3.282 0 00-3.281 3.281V5.062a3.282 3.282 0 013.281-3.28h26.906v30.187z"
          fill="#3F73E3"
        />
        <path
          d="M47.156 38.531H20.25a3.282 3.282 0 010-6.562h26.906v6.562zM37.969 23.438V17.53L55.03.47v5.288c0 4.178-1.66 8.184-4.612 11.138l-6.544 6.543h-5.906z"
          fill="#ECECEC"
        />
        <path
          d="M33.563 26.911l15.748-15.747.928.928-15.748 15.747-.928-.928z"
          fill="#5384EE"
        />
        <path
          d="M32.063 16.875a5.906 5.906 0 100-11.813 5.906 5.906 0 000 11.813z"
          fill="#fff"
        />
        <path
          d="M32.063 11.625a1.969 1.969 0 100-3.937 1.969 1.969 0 000 3.937z"
          fill="#83B4FF"
        />
        <path
          d="M32.062 11.625a3.934 3.934 0 00-3.927 3.746 5.876 5.876 0 007.855 0 3.934 3.934 0 00-3.928-3.746z"
          fill="#83B4FF"
        />
        <g fill="#83B4FF">
          <path d="M26.156 26.719h6.563v1.312h-6.563V26.72zM26.156 23.438h8.532v1.312h-8.532v-1.313zM26.156 20.156H36v1.313h-9.844v-1.313z" />
        </g>
        <g clipPath="url(#BiographyPlusIcon1)">
          <path
            d="M0 36.5c0-7.732 6.268-14 14-14s14 6.268 14 14-6.268 14-14 14-14-6.268-14-14z"
            fill="#3F73E3"
          />
          <path
            d="M2 36.5c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"
            fill="#fff"
          />
          <path
            d="M8.375 35.469a.834.834 0 00-.645.293.834.834 0 00-.293.644v.938c0 .254.098.478.293.674.176.175.391.263.645.263h4.219V42.5c0 .254.097.469.293.645.175.195.39.292.644.292h.938a.926.926 0 00.674-.292.876.876 0 00.263-.645v-4.219h4.219a.978.978 0 00.674-.263.978.978 0 00.264-.674v-.938a.877.877 0 00-.264-.644.926.926 0 00-.674-.293h-4.219V31.25a.876.876 0 00-.263-.645.926.926 0 00-.674-.293h-.938a.834.834 0 00-.644.293.834.834 0 00-.293.645v4.219H8.375z"
            fill="#3F73E3"
          />
        </g>
      </g>
      <defs>
        <clipPath id="BiographyPlusIcon0">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h55v50H0z" />
        </clipPath>
        <clipPath id="BiographyPlusIcon1">
          <path fill="#fff" transform="translate(0 21.5)" d="M0 0h29v29H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BiographyPlusIcon;
